@media (max-width: 575px) {
  .edit.ant-form > .ant-form-item {
    margin-bottom: 0px;
    width: 50%;
    display: inline-block;
  }
  .edit.ant-form > .ant-form-item:nth-child(2) {
    padding-left: 8px;
  }
}

@media (min-width: 1200px) {
  .flex {
    display: flex;
  }
}

.textAreaWithLines {
  display: flex;
  width: 100%;
  overflow: hidden;
  max-height: 690px;
}
.textAreaWithLines .lineObj {
  width: 30px;
  padding: 4px 2px;
  color: #aeaeae;
  text-align: right;
}
.textAreaWithLines .ant-input-textarea {
  width: 100%;
}
.textAreaWithLines textarea {
  resize: none;
}
